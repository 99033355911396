import { UserModel, UserStatus } from '../index';

export interface WeekDayTimetable {
  weekday: number,
  startingTime: string,
  endingTime: string
}

// export interface PartnerModel {
//   id?: string;

//   name?: string | null;
//   picture: string | null;

//   description: string | null;
//   timetable: string | WeekDayTimetable[] | null;
//   address: string | null;

//   userId: UserModel['id'];
//   user?: UserModel;

//   createdAt: Date;
//   updatedAt: Date;
// }

export class PartnerModel {

  constructor(
    public id: string,
    public name: string,
    public picture: string,
    public description: string,
    public email: string,
    public phone: string,
    public link: string,
    public timetable: string | WeekDayTimetable[] | null,
    public status: UserStatus,
    public address: string,
    public excludingTimes: string[],
    public excludingDates: string[],
    public createdAt: Date,
    public updatedAt: Date,
  ) { }

  get isInitialized(): boolean {
    return !!this.address && !!this.name && !!this.picture;
  }

  get isActive(): boolean {
    return this.status === UserStatus.ACTIVE && this.isInitialized;
  }
}