import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { exhaustMap, take } from 'rxjs/operators';

/** 
 * Models 
 */
import { AuthenticationModel } from '@models';

/** 
 * Services 
 */
import { AuthenticationService } from '@services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return this.authenticationService.authenticationSub$
            .pipe(
                take(1),
                exhaustMap((user: AuthenticationModel | null) => {

                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${user?.token}`
                        }
                    });

                    return next.handle(request);
                }));
    }
}