import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, switchMapTo, take, takeUntil, tap } from 'rxjs/operators';

/**
 * DTOs
 */
import { LoginDto, EmailDto, ResetPasswordDto, TokenDto, UpdatePasswordDto } from '@dtos';

/** 
 * Interfaces 
 */
import { ResponseDataInterface, ResponseMessageInterface, SuccessResponseStatus } from '@interfaces';

/**
 * Models
 */
import { AuthenticationModel, UserModel } from '@models';

/**
 * Environment
 */
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private apiURL: string = '';

  constructor(
    private http: HttpClient,
  ) {
    this.apiURL = `${environment.URL_API}/authentication`;
  }

  /** 
   * 
   * Authentication 
   * 
   */

  public login(data: LoginDto): Observable<AuthenticationModel> {
    return this.http
      .post<ResponseDataInterface>(`${this.apiURL}/login`, { ...data })
      .pipe(
        map(
          (response: ResponseDataInterface) => {
            return (response.data as AuthenticationModel);
          }));
  }

  public askResetPassword(data: EmailDto): Observable<ResponseMessageInterface> {
    return this.http
      .post<ResponseMessageInterface>(`${this.apiURL}/reset_password`, { ...data })
      .pipe(
        map(
          (response: ResponseMessageInterface) => {
            return response;
          }));
  }

  public checkResetPassword(data: TokenDto): Observable<ResponseMessageInterface> {
    return this.http
      .get<ResponseMessageInterface>(`${this.apiURL}/reset_password/${data.token}`)
      .pipe(
        map(
          (response: ResponseMessageInterface) => {
            return response;
          }));
  }

  public completeResetPassword(data: ResetPasswordDto): Observable<ResponseMessageInterface> {
    return this.http
      .put<ResponseMessageInterface>(`${this.apiURL}/reset_password`, { ...data })
      .pipe(
        map(
          (response: ResponseMessageInterface) => {
            return response;
          }));
  }

  public updatePassword(data: UpdatePasswordDto): Observable<ResponseMessageInterface> {
    return this.http
      .put<ResponseMessageInterface>(`${this.apiURL}/update_password`, { ...data })
      .pipe(
        map(
          (response: ResponseMessageInterface) => {
            return response;
          }));
  }

  /**
   * 
   * Secondary Functions
   * 
   */

  public authenticationSub$ = new BehaviorSubject<AuthenticationModel | null>(null);
  private refreshTokenTimeout: number | undefined;

  public logIn(data: LoginDto): Observable<ResponseDataInterface | ResponseMessageInterface> {
    return this.http
      .post<ResponseDataInterface>(`${this.apiURL}/login`, { ...data })
      .pipe(
        tap(
          (response: ResponseDataInterface) => {

            if (response.status === SuccessResponseStatus.OK) {
              this.handleAuthentication(response.data as AuthenticationModel
                // (response.data as AuthUserModel)._token!,
                // (response.data as AuthUserModel)._expiresIn!,
                // (response.data as AuthUserModel).refresh!,
                // (response.data as AuthUserModel).user
              );
            }

            return response.status;
          }));
  }

  public logOut(): Observable<string> {
    return this.authenticationSub$
      .pipe(
        take(1),
        exhaustMap(
          ((user: AuthenticationModel | null) => {
            return this.revokeToken(user);
          })
        )
      )
  }

  public autoLogIn() {
    const authUser: AuthenticationModel = JSON.parse(localStorage.getItem('authUser')!);

    if (!authUser) {
      return;
    }
    const loadUser = new AuthenticationModel(
      authUser._token,
      authUser._expiresIn,
      authUser._expirationDate,
      authUser.refresh,
      authUser.user
    );

    if (loadUser.token) {
      this.authenticationSub$.next(loadUser);
      this.startRefreshTokenTimer(loadUser);
    } else {
      this.refreshToken(loadUser).pipe(take(1)).subscribe();
    }
  }

  public autoLogOut() {
    this.stopRefreshTokenTimer();
    this.authenticationSub$.next(null);
    localStorage.removeItem('authUser');
  }

  private handleAuthentication(data: AuthenticationModel) {

    let expirationDate: Date = new Date((new Date().getTime()) + data._expiresIn * 1000);
    const newUser = new AuthenticationModel(data._token, data._expiresIn, expirationDate, data.refresh, data.user)
    this.authenticationSub$.next(newUser);
    localStorage.setItem('authUser', JSON.stringify(newUser));

    this.startRefreshTokenTimer(newUser);
  }

  private refreshToken(user: AuthenticationModel) {
    return this.http
      .post<ResponseDataInterface>(`${this.apiURL}/refresh-token`, { token: user.refresh })
      .pipe(
        tap((response: ResponseDataInterface) => {
          this.handleAuthentication(response.data as AuthenticationModel)
        })
      );
  }

  private revokeToken(user: AuthenticationModel | null): Observable<string> {
    return this.http
      .post<ResponseMessageInterface>(`${this.apiURL}/revoke-token`, { token: user!.refresh })
      .pipe(
        map(
          (response: ResponseMessageInterface) => {
            this.stopRefreshTokenTimer();
            this.authenticationSub$.next(null);
            localStorage.removeItem('authUser');
            return response.message!;
          }));
  }

  private startRefreshTokenTimer(user: AuthenticationModel) {
    this.stopRefreshTokenTimer();
    const timeout = new Date(user.tokenExpirationDate).getTime() - new Date().getTime() - 1000;
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken(user).subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  /**
 * User 
 */

  public readUser() {
    return this.authenticationSub$
      .pipe(
        take(1),
        map(
          (data: AuthenticationModel | null) => {

            if (!data) return null;

            return (data!['user'] as UserModel);
          }));
  }

  public updateUser(data: AuthenticationModel, user: UserModel) {
    const newUser = new AuthenticationModel(data._token, data._expiresIn, data._expirationDate, data.refresh, user)

    this.authenticationSub$.next(newUser);
    localStorage.setItem('authUser', JSON.stringify(newUser));
  }
}
