import * as moment from 'moment';
import { WeekDayTimetable, PartnerModel } from '../index';

export enum EventType {
  ONETIME = 'ONETIME',
  RECURRING = 'RECURRING'
}
// export interface EventModel {
//   id?: string;
//   name: string;
//   description: string;
//   place: string;
//   startingDate: Date;
//   endingDate: Date;
//   timetable: string | WeekDayTimetable[] | null;
//   excludingDates: String[],
//   startingBooking: number[],
//   duration: number;
//   type: EventType;

//   partnerId?: PartnerModel['id'];
//   partner?: PartnerModel;

//   createdAt?: Date;
//   updatedAt?: Date;
// }

export class EventModel {

  constructor(
    public id: string,
    public name: string,
    public description: string,
    public place: string,
    public startingDate: Date,
    public endingDate: Date,
    public timetable: string | WeekDayTimetable[] | null,
    public excludingTimes: string[],
    public excludingDates: string[],
    public startingBooking: number[],
    public duration: number,
    public per_slot: number,
    public type: EventType,

    public published?: boolean,

    public partnerId?: PartnerModel['id'],
    public partner?: PartnerModel,

    public createdAt?: Date,
    public updatedAt?: Date
  ) { }

  get isActive(): boolean {
    let currentDate = moment().startOf('day');
    let startingDate = moment(this.startingDate).startOf('day');
    let endingDate = moment(this.endingDate).startOf('day');
    return (
      ((this.type as EventType) == EventType.RECURRING) && currentDate.isSameOrBefore(endingDate, 'day')
      // && (moment(this.startingDate) < moment())
      // && (moment(this.endingDate) > moment())
    ) || (
        ((this.type as EventType) == EventType.ONETIME) && currentDate.isSameOrBefore(startingDate, 'day')
      )
    // && (moment(this.startingDate) > moment());
  }

  get isVisible(): boolean {
    let currentDate = moment().startOf('day');
    let startingDate = moment(this.startingDate).startOf('day');
    let diff = startingDate.diff(currentDate, "days");
    console.log(currentDate.format("YYYY-MM-DD"), startingDate.format("YYYY-MM-DD"))
    console.log(this.startingBooking[0], diff);
    console.log((
      (this.startingBooking[0] > 0) && (this.startingBooking[0] >= diff)
    ) || (
        this.startingBooking[0] == 0
      ))
    return (
      (this.startingBooking[0] > 0) && (this.startingBooking[0] >= diff)
    ) || (
        this.startingBooking[0] == 0
      )
  }

  get isPublished(): boolean {
    return this.published;
  }
}