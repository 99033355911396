import { AdministratorModel, PartnerModel } from '../index';

export enum UserRole {
  ADMINISTRATOR = 'ADMINISTRATOR',
  PARTNER = 'PARTNER'
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

// export interface UserModel {
//   id?: string;

//   email: string;
//   password: string;

//   reset_token: string;
//   reset_expiration: Date;

//   role: UserRole;
//   status: UserStatus;

//   Administrator?: AdministratorModel;
//   Partner?: PartnerModel;
// }

export class UserModel {

  constructor(
    public id: string,
    public email: string,
    public role: UserRole,
    public status: UserStatus,
    public createdAt: Date,
    public updatedAt: Date,
    public Administrator?: AdministratorModel,
    public Partner?: PartnerModel
  ) { }

  get isActive(): boolean {
    return this.status === UserStatus.ACTIVE;
  }
}