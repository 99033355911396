import { UserModel }  from '../index';

export class AuthenticationModel {

  constructor(
    public _token: string,
    public _expiresIn: number,
    public _expirationDate: Date,
    public refresh: string,
    public user: UserModel
  ) {
  }

  get tokenExpirationDate() {
    return this._expirationDate;
    // return new Date((new Date().getTime()) + this._expiresIn * 1000);
  }

  get token() {
    if (!this.tokenExpirationDate || new Date() >= new Date(this.tokenExpirationDate)) {
      return null;
    }
    return this._token;
  }
}

// export class AuthenticationModel {

//   constructor(
//     public _token: string,
//     public _expiresIn: number,
//     public refresh: string,
//     public user: UserModel
//   ) {
//   }

//   get tokenExpirationDate() {
//     return new Date((new Date().getTime()) + this._expiresIn * 1000);
//   }

//   get token() {
//     if (!this.tokenExpirationDate || new Date() >= new Date(this.tokenExpirationDate)) {
//       return null;
//     }
//     return this._token;
//   }
// }