import { AuthenticationModel, BookingModel, EventModel, FileModel, PartnerModel, StatisticsModel } from "@models";

export interface ResponseMessageInterface {
  message: SuccessResponseMessage | ErrorResponseMessage;
  status: SuccessResponseStatus | ErrorResponseMessage;
}

export interface ResponseDataInterface {
  data: {
    [key: string]:
    PartnerModel | PartnerModel[] |
    EventModel | EventModel[] |
    BookingModel | BookingModel[] |
    StatisticsModel
  } |
  AuthenticationModel | FileModel
  status: SuccessResponseStatus | ErrorResponseMessage;
}

export enum SuccessResponseStatus {
  OK = 'OK', // 200
  CREATED = 'CREATED', // 201
  ACCEPTED = 'ACCEPTED', // 202
  NO_CONTENT = 'NO_CONTENT' // 204
}

export enum ErrorResponseStatus {
  BAD_REQUEST = '400',
  UNAUTHORIZED = '401',
  FORBIDDEN = '403',
  NOT_FOUND = '404',
  UNPROCESSABLE_ENTITY = '422'
}

/** HTTP STATUS CODE - 200 OK */
/** HTTP STATUS CODE - 201 CREATED */
/** HTTP STATUS CODE - 202 ACCEPTED */
/** HTTP STATUS CODE - 204 NO CONTENT */

export enum SuccessResponseMessage {

  // 200
  PASSWORD_UPDATED = 'PASSWORD_UPDATED',
  TOKEN_VALID = 'TOKEN_VALID',
  PASSWORD_RESTORED = 'PASSWORD_RESTORED',
  TOKEN_REVOKED = 'TOKEN_REVOKED',

  ACCOUNT_DELETED = 'ACCOUNT_DELETED',
  PARTNER_DELETED = 'PARTNER_DELETED',
  EVENT_DELETED = 'EVENT_DELETED',
  BOOKING_DELETED = 'BOOKING_DELETED',

  // 202
  NEED_EMAIL_VERIFICATION = 'NEED_EMAIL_VERIFICATION',
  NEED_PASSWORD_VERIFICATION = 'NEED_PASSWORD_VERIFICATION',
  NEED_ACTIVATION = 'NEED_ACTIVATION',
}


/** HTTP STATUS CODE 400 - BAD REQUEST*/
/** HTTP STATUS CODE 401 - UNAUTHORIZED */
/** HTTP STATUS CODE 403 - FORBIDDEN */
/** HTTP STATUS CODE 404 - NOT FOUND */
/** HTTP STATUS CODE 422 - UNPROCESSABLE ENTITY */

export enum ErrorResponseMessage {

  // 404 (General)
  URL_NOT_EXISTS = 'URL_NOT_EXISTS',

  // 404 (Register & Authentication)
  ALREADY_REGISTERED = 'ALREADY_REGISTERED',
  WRONG_CREDENTIALS = 'WRONG_CREDENTIALS',
  INVALID_TOKEN = 'INVALID_TOKEN',
  INVALID_REFRESH_TOKEN = 'INVALID_REFRESH_TOKEN',
  ALREADY_VERIFIED = 'ALREADY_VERIFIED',

  // 404 (Booking)
  INVALID_DATETIME = 'INVALID_DATETIME',
  INVALID_DATE = 'INVALID_DATE',
  INVALID_TIME = 'INVALID_TIME',
  BOOKING_UNAVAILABLE = 'BOOKING_UNAVAILABLE'
}

export enum SuccessResponseMessage {

}

export enum ErrorResponseMessage {

}