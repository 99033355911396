import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, registerLocaleData } from '@angular/common';
import { LanguageInterface } from '../_interfaces/lib/language.interface';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
// import { LocaleService } from './locale.service';

import localeEnglish from '@angular/common/locales/en';
import localeGreece from '@angular/common/locales/el';
import { LocaleService } from '@services/_locale.service';

export interface Locale {
  lang: string;
  // tslint:disable-next-line:ban-types
  data: Object;
}

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  // Private properties
  private langIds: any = [];

  /**
   * Service Constructor
   *
   * @param translate: TranslateService
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    private locale: LocaleService,
    @Inject(LOCALE_ID) private _locale: string,) {

    // add new langIds to the list
    // this.translate.addLangs(['en']);

    // this language will be used as a fallback when a translation isn't found in the current language
    // this.translate.setDefaultLang('en');
  }


  // /**
  //  * Load Translation
  //  *
  //  * @param args: Locale[]
  //  */
  // loadTranslations(...args: Locale[]): void {
  //   console.log("this.loadTranslations");
  //   console.log(args);

  //   const locales = [...args];

  //   locales.forEach(locale => {
  //     // use setTranslation() with the third argument set to true
  //     // to append translations instead of replacing them
  //     this.translate.setTranslation(locale.lang, locale.data, true);

  //     this.langIds.push(locale.lang);
  //   });

  //   // add new languages to the list
  //   this.translate.addLangs(this.langIds);
  // }

  /**
   * Setup language
   *
   * @param lang: any
   */
  setLanguage(lang: LanguageInterface['lang']) {
    if (lang) {
      this.translate.use(lang);
      this.locale.registerCulture(lang)

      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem('language', lang);
      }
    }
  }

  initializeLanguage(lang: LanguageInterface['lang']) {
    if (localStorage.getItem('language')) {
      this.setLanguage(localStorage.getItem('language'));
    } else {
      this.setLanguage(lang);
    }
  }

  /**
   * Returns selected language
   */
  getSelectedLanguage(): any {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('language') || this.translate.getDefaultLang();
    }
    else {
      return this.translate.getDefaultLang();
    }
  }

  getAvailableLanguages(): LanguageInterface[] {
    return [{
      name: 'greek',
      translation: 'LANGUAGE.GREEK',
      icon: 'el_flag',
      lang: 'el'
    }, {
      name: 'english',
      translation: 'LANGUAGE.ENGLISH',
      icon: 'en_flag',
      lang: 'en'
    }]
  }
}