import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/** 
 * Interfaces 
 */
import { ErrorResponseMessage } from '@interfaces';

/** 
 * Services 
 */
import { AuthenticationService } from '@services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            let msg: string = '';

            // console.log(" - - - - - - - - - - ");
            // console.log("Error (interceptor)");
            // console.log(err.error.message);
            // console.log(" - - - - - - - - - - ");

            if (err.status == 401 || err.error.message == ErrorResponseMessage.INVALID_REFRESH_TOKEN) {
                this.authenticationService.autoLogOut();
                setTimeout(() => { this.router.navigateByUrl('/partners') });
            } else if (err.status == 400) {
                msg = 'BAD_REQUEST';
            } else if (err.status == 401) {
                msg = 'UNAUTHORIZED';
            } else if (err.status == 403) {
                msg = 'FORBIDDEN';
            } else if (err.status == 404) {
                msg = err.error.message;
            } else if (err.status == 422) {
                msg = 'UNPROCESSABLE_ENTITY';
            } else if (err.status >= 500) {
                msg = 'SERVER_ERROR';
            } else {
                msg = 'UNKNOWN_ERROR';
            }

            return throwError({ ...err, message: msg });
        }))
    }
}