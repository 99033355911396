import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule, MissingTranslationHandler, MissingTranslationHandlerParams, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorIntl } from '@angular/material/paginator';

/**
 * Routing Module
 */
import { AppRoutingModule } from './app-routing.module';

/**
 * Interceptors
 */
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { ErrorInterceptor } from './_interceptors/error.interceptor';

/**
 * Material Modules
 */
import { MatDialogRef } from '@angular/material/dialog';


/**
 * Components
 */
import { AppComponent } from './app.component';
import { LayoutComponent } from '@layouts/layout/layout.component';
import { LayoutPublicComponent } from '@layouts/layout-public/layout-public.component';

/**
 * Initializer
 */
import { appInitializer } from './app.initializer';

/**
 * Modules
 */
import { ViewsModule } from '@views/views.module';

/**
 * Services
 */
import { AuthenticationService } from '@services/authentication.service';
import { TranslationService } from '@services/_translation.service';
import { LocaleService } from '@services/_locale.service';
import { PaginatorI18n } from '@services/_paginator.service';
import { LayoutFramedComponent } from '@layouts/layout-framed/layout-framed.component';

// import { registerLocaleData } from '@angular/common';
// import localeEl from '@angular/common/locales/el';
// import localeEn from '@angular/common/locales/en';
// import { TranslationService } from '@services/_translation.service';
// registerLocaleData(localeEl);
// registerLocaleData(localeEn);

// import {appLocalizer} from './app.localizer';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return '<<this text is untranslated>>';
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    LayoutPublicComponent,
    LayoutFramedComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    HttpClientModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
      useDefaultLang: true,
    }),

    ViewsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthenticationService]
    },
    { provide: LOCALE_ID, useValue: 'el-GR' },
    {
      provide: LOCALE_ID,
      useFactory: (_localeService: LocaleService) => _localeService.locale,
      deps: [LocaleService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MatPaginatorIntl, deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
    }
  ],
  exports: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

