import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

/**
 * Material Modules
 */
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

/**
 * Components
 */
import { EventCardComponent } from './lib/cards/event-card/event-card.component';
import { PartnerCardComponent } from './lib/cards/partner-card/partner-card.component';

import { PartnerItemComponent } from './lib/items/partner-item/partner-item.component';
import { EventItemComponent } from './lib/items/event-item/event-item.component';
import { BookingItemComponent } from './lib/items/booking-item/booking-item.component';
import { TermsItemComponent } from './lib/items/terms-item/terms-item.component';

import { ConfirmationDialogComponent } from './lib/dialogs/confirmation-dialog/confirmation-dialog.component';
import { PasswordDialogComponent } from './lib/dialogs/password-dialog/password-dialog.component';
import { MessageDialogComponent } from './lib/dialogs/message-dialog/message-dialog.component';
import { TermsDialogComponent } from './lib/dialogs/terms-dialog/terms-dialog.component';

import { LanguageSelectorComponent } from './lib/language-selector/language-selector.component';

import { LoadingComponent } from './lib/extra/loading/loading.component';
import { NoDataComponent } from './lib/extra/no-data/no-data.component';


/**
 * Pipes
 */
import { SearchFilterPipe } from '../_pipes/lib/search-filter.pipe';
import { FilterEventsByStatusPipe } from '../_pipes/lib/filter-events-status.pipe';
import { FilterPartnersByStatusPipe } from '@pipes';

const MAT_MODULES = [
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
];

@NgModule({
  declarations: [
    EventCardComponent, PartnerCardComponent,
    ConfirmationDialogComponent, PasswordDialogComponent, MessageDialogComponent, TermsDialogComponent,
    LanguageSelectorComponent,
    FilterEventsByStatusPipe, SearchFilterPipe, FilterPartnersByStatusPipe,
    PartnerItemComponent, EventItemComponent, BookingItemComponent,
    LoadingComponent, NoDataComponent,
    TermsItemComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,

    ...MAT_MODULES,
  ],
  providers: [],
  exports: [
    EventCardComponent, PartnerCardComponent,
    ConfirmationDialogComponent, PasswordDialogComponent, MessageDialogComponent, TermsDialogComponent,
    LanguageSelectorComponent,
    FilterEventsByStatusPipe, SearchFilterPipe, FilterPartnersByStatusPipe,
    PartnerItemComponent, EventItemComponent, BookingItemComponent,
    LoadingComponent, NoDataComponent,
    TermsItemComponent,

    ...MAT_MODULES
  ]
})
export class ViewsModule { }
